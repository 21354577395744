<template>
  <v-main
    id="pages"
    class="page-public grey lighten-3"
    :style="`background: url(${bg}) center top / cover no-repeat`"
  >
    <div class="overlay" />
    <v-responsive min-height="100vh" class="page-content">
      <slot><router-view /></slot>
    </v-responsive>
    <notifications />
  </v-main>
</template>

<script>
import Notifications from "../../../dashboard/components/Notifications";

const bgs = [
  "https://uploads-ssl.webflow.com/5ddd9889a0ceb069bad95c45/5de56d026398e3446298dbb3_mechanic.jpg",
  "https://uploads-ssl.webflow.com/5ddd9889a0ceb060a0d95c64/5dea90ecb80bb8283fbd6ee2_Trucking.jpg",
  "https://uploads-ssl.webflow.com/5ddd9889a0ceb060a0d95c64/5de57187f8e71101913be75b_small_business_couple.jpg",
  "https://uploads-ssl.webflow.com/5ddd9889a0ceb060a0d95c64/5defe4e1a1729b81e896a1cd_Trucking02.jpg",
  "https://uploads-ssl.webflow.com/5ddd9889a0ceb069bad95c45/5de54b60c96eff797753f44b_architect.jpg",
  "https://uploads-ssl.webflow.com/5ddd9889a0ceb069bad95c45/5de54b65a56f319d7608cf29_truck_driver.jpg",
  "https://uploads-ssl.webflow.com/5ddd9889a0ceb069bad95c45/5de54b676398e33b3a97608a_small_business_warehouse-managers.jpg",
];
export default {
  name: "PagesCoreView",

  components: {
    Notifications,
  },

  data: () => ({
    bg: bgs[Date.now() % bgs.length],
  }),

  computed: {
    // src() {
    //   return this.srcs[this.$route.path];
    // },
  },
};
</script>

<style lang="scss" scoped>
.page-public {
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
  }
  .page-content {
    position: relative;
    z-index: 2;
  }
}
</style>