@ -0,0 +1,38 @@
<template>
  <index-page>
    <v-container class="error-page fill-height" tag="section">
      <v-row class="text-center" justify="center">
        <v-col cols="auto">
          <h1 class="title white--text font-weight-black">404</h1>

          <div class="display-3 white--text mb-5 mt-10">Page not found :(</div>

          <v-btn depressed color="grey darken-3" @click="back"
            >Get me out of here!</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </index-page>
</template>

<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import IndexPage from "./Index.vue";

@Component({
  components: {
    IndexPage,
  },
})
export default class NotFound extends Vue {
  back() {
    this.$router.replace("/");
  }

  @Watch("$route")
  onRouteChange() {
    this.$store.commit("SET_NOT_FOUND", false);
  }
}
</script>
