<template>
  <v-card
    v-bind="$attrs"
    :class="classes"
    class="v-card--material px-2 py-1"
    elevation="4"
  >
    <div
      class="d-flex grow card-head align-center"
      :class="{ 'flex-wrap': !titleNowrap }"
    >
      <v-avatar
        v-if="avatar"
        size="128"
        class="mx-auto v-card--material__avatar elevation-6"
        color="grey"
      >
        <v-img :src="avatar" />
      </v-avatar>

      <v-sheet
        v-else-if="icon || $slots.heading || $slots.image"
        :class="{
          'pa-5': !$slots.image,
          'elevation-6': delayed,
          'elevation-0': !delayed,
        }"
        :color="color"
        :max-height="icon ? 72 : undefined"
        :width="inline || icon ? 'auto' : '100%'"
        class="text-start v-card--material__heading mb-n6 transition-swing"
        dark
      >
        <slot v-if="$slots.heading" name="heading" />

        <slot v-else-if="$slots.image" name="image" />

        <!-- <div v-else-if="title && !icon" class="display-1 font-weight-light" v-text="title" /> -->

        <v-icon v-else-if="icon" size="32" v-text="icon" />

        <div v-if="text" class="headline font-weight-thin" v-text="text" />
      </v-sheet>

      <v-col v-if="hoverReveal" cols="12" class="text-center py-0 mt-n12">
        <slot name="reveal-actions" />
      </v-col>

      <div v-else-if="title" :class="{ 'ml-3': !!icon }">
        <div class="card-title">
          <span>{{ title }}</span>
          <slot v-if="$slots['after-title']" name="after-title" />
        </div>
        <slot v-if="$slots['second-title']" name="second-title" />
      </div>

      <div v-if="$slots['after-heading']" class="ml-6" style="flex: 1">
        <slot v-if="$slots['after-heading']" name="after-heading" />
      </div>
    </div>

    <v-divider v-if="title" />
    <slot />

    <template v-if="$slots.actions">
      <v-divider />
      <v-card-actions class="pb-0">
        <slot name="actions" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
export default {
  name: "MaterialCard",

  props: {
    avatar: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "green",
    },
    hoverReveal: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    image: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    titleNowrap: {
      type: Boolean,
      default: false,
    },
  },

  data: () => {
    return { delayed: false };
  },

  computed: {
    classes() {
      return {
        "v-card--material--has-heading": this.hasHeading,
        "v-card--material--hover-reveal": this.hoverReveal,
        "pb-2": !this.$slots.actions,
      };
    },
    hasHeading() {
      return Boolean(this.$slots.heading || this.title || this.icon);
    },
    hasAltHeading() {
      return Boolean(this.$slots.heading || (this.title && this.icon));
    },
  },

  created() {
    setTimeout(() => {
      this.delayed = true;
    });
  },
};
</script>

<style lang="sass">
.v-card--material
  &__avatar
    position: relative
    top: -64px
    margin-bottom: -32px

  &__heading
    position: relative
    top: -40px
    transition: .3s ease
    z-index: 1

  &.v-card--material--hover-reveal:hover
    .v-card--material__heading
      transform: translateY(-40px)
  // .transition-swing
      transition-duration: 2s !important
      transition-delay: 1s !important
</style>
