<template>
  <div>
    <forbidden-page v-if="forbidden" />
    <not-found-page v-else-if="notFound" />
    <router-view v-if="!forbidden && !notFound && configLoaded" />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { http, NotificationResource } from "@/resources";
import loadConfig from "./helpers/loadConfig";
import { Config } from "./resources/interfaces";
import store from "./store";
import { mapMutations, mapState } from "vuex";
import ForbiddenPage from "@/views/pages/Forbidden.vue";
import NotFoundPage from "@/views/pages/Error.vue";

@Component({
  components: {
    ForbiddenPage,
    NotFoundPage,
  },
  computed: {
    ...mapState(["forbidden", "notFound"]),
  },
  methods: {
    ...mapMutations({
      loadBellNotifications: "LOAD_BELL_NOTIFICATIONS",
    }),
  },
})
export default class App extends Vue {
  configLoaded = false;

  async created() {
    http.interceptors.request.use(this.requestFullfilled);
    http.interceptors.response.use(
      this.responseFullfilled,
      this.responseRejected
    );

    if (store.state.token) {
      await this.getConfig(this.$config);
      NotificationResource.query({
        isDone: false,
      }).then((result) => {
        // @ts-ignore
        this.loadBellNotifications(result);
      });
    } else {
      const publicConfig = await http.get("config");
      this.$config = {
        ...this.$config,
        ...(publicConfig.data || {}),
      };
    }
    this.configLoaded = true;
    setTimeout(() => this.registerRouterHook(), 1000);
  }

  registerRouterHook() {
    this.$router.beforeEach((to, from, next) => {
      this.$pageTitle = "";
      next();
    });

    this.$router.beforeResolve(async (to, from, next) => {
      console.log(`[App] Before resolve ${to.path}.`);

      next();
    });
  }

  requestFullfilled(request: AxiosRequestConfig) {
    this.$pendingRequests++;

    // stop request and return 401 response when no token exist except for login request
    if (
      ![
        "auth/login",
        "auth/forgot-login",
        "auth/forgot-password",
        "config",
      ].includes(request.url || "") &&
      !store.state.token
    ) {
      this.$pendingRequests--;
    }
    return request;
  }

  responseFullfilled(response: AxiosResponse) {
    this.$pendingRequests--;
    return response;
  }

  responseRejected(err: any) {
    // console.log("App.responseRejected", err);
    this.$pendingRequests--;
    const { response } = err;
    if (!response) {
      if (err.message === "Network Error") {
        const message = err.message;
        this.$notify(message, "error");
      }
      return Promise.reject(err);
    }
    if (response.status >= 500) {
      let message = "Internal Server Error";
      if (response.status === 502) {
        message = "Service is Down";
      }
      if (response.status === 504) {
        message = "Service Timeout";
      }
      this.$notify(message, "error");

      return Promise.reject(new Error(message));
    } else if (response.status >= 400) {
      const message = response.data.message || response.statusText;
      this.$notify(message, "error");
      return Promise.reject(new Error(message));
    }
    return response;
  }

  async getConfig(configLoaded?: Config) {
    this.$config = await loadConfig(configLoaded);
    // console.log(`[App] Config loaded.`);
  }
}
</script>

<style>
.comma-split:not(:last-child):after {
  content: ",";
  margin-right: 3px;
}
.sortable-ghost {
  background: var(--v-primary-lighten5);
}
.sortable-drag {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
