<template>
  <v-dialog v-model="visible" width="800px" class="config-reader">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        plain
        small
        :ripple="false"
        v-bind="attrs"
        v-on="on"
        class="btn-text"
      >
        {{ text }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-left ml-0">
        <span class="text-h4 font-weight-bold">{{ config[title] }}</span>
      </v-card-title>
      <v-card-text>
        <div class="external-config-reader" v-html="config[content]" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import type { Config } from "@/resources/interfaces";

@Component({
  components: {},
})
export default class ConfigReader extends Vue {
  @Prop()
  text!: string;

  @Prop()
  configKey!: keyof Config;

  @Prop({ default: "title" })
  title!: string;

  @Prop({ default: "content" })
  content!: string;

  visible = false;

  get config() {
    return this.$config[this.configKey] || {};
  }
}
</script>

<style lang="scss" scoped>
.btn-text ::v-deep {
  margin-right: 6px;
  .v-btn__content {
    font-size: 12px !important;
  }
}
.external-config-reader ::v-deep {
  h1 {
    font-size: 28px;
    font-weight: 800;
    margin: 12px 0;
  }
  ul {
    margin-bottom: 16px;
  }
  ul li,
  p {
    font-size: 14px;
    line-height: 21px;
  }
}
</style>