<template lang="pug">
.comp-paginate-input.d-flex.align-center.justify-center(v-if="length > 0")
  span.mr-4(v-if="total >= 0") Total {{ total }}
  v-icon.v-pagination__navigation.page-nav(
    @click="setCurrentPage(1)",
    :class="{ 'v-pagination__navigation--disabled': value === 1 }"
  ) mdi-chevron-double-left
  v-pagination(
    :length="length",
    :total-visible="totalVisible",
    :value="value",
    @input="setCurrentPage"
  )
  v-icon.v-pagination__navigation.page-nav(
    @click="setCurrentPage(length)",
    :class="{ 'v-pagination__navigation--disabled': value === length }"
  ) mdi-chevron-double-right
  .ml-4.d-flex.align-center(style="width: 90px")
    span.mr-4 Page
    simple-number.mt-0(
      hide-details,
      :currency="false",
      dense,
      type="number",
      :value="value",
      @change="handleInput",
      :min="1",
      :max="length"
    )
</template>

<script>
import SimpleNumber from "@/views/dashboard/components/common/SimpleNumber.vue";
export default {
  name: "BasePaginateInput",
  components: { SimpleNumber },
  props: {
    totalVisible: {
      type: Number,
      default: 10,
    },
    total: Number,
    length: Number,
    value: Number,
  },
  methods: {
    setCurrentPage(v) {
      if (!v) return;
      this.$emit("input", v);
    },
    handleInput(p) {
      this.setCurrentPage(+p);
    },
  },
};
</script>

<style lang="scss" scoped>
.comp-paginate-input {
  .page-nav {
    margin-top: 4px;
    margin-left: 4px;
    margin-right: 4px;
    &::after {
      background: none;
    }
    &.disabled {
      color: red;
    }
  }
}
</style>