<template>
  <v-app>
    <!-- <pages-core-app-bar /> -->

    <pages-core-view>
      <slot></slot>
    </pages-core-view>

    <pages-core-footer />
  </v-app>
</template>

<script>
// import AppBar from "./components/core/AppBar";
import Footer from "./components/core/Footer";
import View from "./components/core/View";
export default {
  name: "PagesIndex",

  components: {
    PagesCoreFooter: Footer,
    PagesCoreView: View,
  },

  // created() {
  //   this.$vuetify.theme.dark = true;
  // },

  // beforeDestroy() {
  //   this.$vuetify.theme.dark = false;
  // },
};
</script>
