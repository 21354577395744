import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/sass/overrides.sass";

Vue.use(Vuetify);

const theme = {
  // anchor: '#D7431D',
  primary: "#47829A",
  secondary: "#264653",
  accent: "#231F20",
  info: "#00CAE3",
  success: "#339933",
};

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      dark: theme,
      light: theme,
    },
  },
});

