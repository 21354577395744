import Vue from "vue";
import Vuex from "vuex";
import { NotificationResource } from "@/resources";
import { Notification, User, Role } from "@/resources/interfaces";

Vue.use(Vuex);

interface State {
  barColor: string;
  barImage: string;
  drawer: null;
  notifications: {
    message: string;
    type: string;
    timeout: number;
    id: string;
  }[];
  bellNotifications: Notification[];
  token: string;
  loginAsToken: string;
  apiVer: string;
  forbidden: boolean;
  notFound: boolean;
  user: Pick<User, "role" | "passwordExpiresAt" | "tags" | "login"> | null;
}

export default new Vuex.Store<State>({
  state() {
    const _user = window.localStorage.getItem("user");
    let user = null;
    if (_user) {
      try {
        user = JSON.parse(_user);
      } catch (error) {
        console.log("parse user", error);
      }
    }
    return {
      barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
      barImage:
        "https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg",
      drawer: null,
      notifications: [],
      bellNotifications: [],
      token: window.localStorage.getItem("token") || "",
      loginAsToken: window.localStorage.getItem("loginAsToken") || "",
      user,
      apiVer: "0.0.0",
      forbidden: false,
      notFound: false,
    };
  },
  getters: {
    loggedIn: (state) => !!state.token || !!state.loginAsToken,
    passwordExpired: (state) => {
      const { role, passwordExpiresAt, tags } = state.user || {};
      const forceResetPwd = tags?.includes("reset password");
      return (
        forceResetPwd ||
        (role !== "visitor" &&
          passwordExpiresAt &&
          new Date(passwordExpiresAt).valueOf() < Date.now() &&
          !state.loginAsToken)
      );
    },
    isAdmin: (state) => state.user?.role === Role.ADMIN,
  },
  mutations: {
    SET_AUTH_USER(state, user) {
      window.localStorage.setItem("user", JSON.stringify(user));
      state.user = user;
    },
    SET_NOT_FOUND(state, payload) {
      state.notFound = payload;
    },
    SET_FORBIDDEN(state, payload) {
      state.forbidden = payload;
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_SCRIM(state, payload) {
      state.barColor = payload;
    },
    ADD_NOTIFICATION(state, { message, type, timeout }) {
      let id = Date.now().toString();
      if (state.notifications.some((n) => n.id === id)) {
        id += "-1";
      }
      state.notifications = state.notifications.filter(
        (n) => n.message !== message
      );
      state.notifications.push({
        message,
        type,
        timeout,
        id,
      });
    },
    DISMISS_NOTIFICATION(state, index) {
      state.notifications = state.notifications
        .slice(0, index)
        .concat(state.notifications.slice(index + 1));
    },
    LOAD_BELL_NOTIFICATIONS(state, notifications) {
      state.bellNotifications = notifications;
    },
    ADD_BELL_NOTIFICATION(state, notification) {
      state.bellNotifications.push(notification);
    },
    UPDATE_BELL_NOTIFICATION(state, notification) {
      const index = state.bellNotifications.findIndex(
        (n) => n.id === notification.id
      );
      state.bellNotifications.splice(index, 1, notification);
    },
    SET_TOKEN(state, token) {
      window.localStorage.setItem("token", token);
      state.token = token;
    },
    SET_LOGIN_AS_TOKEN(state, token) {
      window.localStorage.setItem("loginAsToken", token);
      state.loginAsToken = token;
    },
    SET_API_VERSION(state, version) {
      state.apiVer = version;
    },
  },
  actions: {
    refreshNotifications({ commit }) {
      NotificationResource.query({
        isDone: false,
      }).then((result) => {
        commit("LOAD_BELL_NOTIFICATIONS", result);
      });
    },
  },
});
