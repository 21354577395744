<template lang="pug">
.simple-number
  v-menu(
    v-if="editing && type === 'date'",
    :value="editing && type === 'date'",
    @input="$event || (editing = false)",
    :activator="$refs.field",
    top,
    left
  )
    v-date-picker(
      :value="value | date('Y-MM-DD')",
      @input="$emit('input', format($event)); $emit('change', format($event)); editing = false"
    )
  v-select.pt-0.mt-0(
    ref="input",
    v-else-if="editing && items",
    v-bind="$attrs",
    :items="items",
    dense,
    hide-details,
    :input-value="value",
    @input="$emit('input', format($event)); editing = false",
    @change="$emit('change', format($event)); editing = false",
    @blur="editing = false",
    @keydown.enter.prevent="editing = false"
  )
    template(#item="{ item }")
      span.font-weight-light.text-uppercase {{ item }}
  v-text-field.pt-0.mt-0(
    ref="input",
    :type="type",
    v-else-if="editing",
    v-bind="$attrs",
    dense,
    hide-details,
    :value="value",
    @input="$emit('input', format($event))",
    @change="$emit('change', format($event))",
    @blur="editing = false",
    @keydown.enter.prevent="editing = false",
    :max="max",
    :min="min"
  )
  .d-flex(
    ref="field",
    v-if="!editing || type === 'date'",
    @click="enterEditing",
    :class="{ editable: !disabled }"
  )
    span.value(v-if="$slots.default")
      slot
    .text-right.pr-3.text-no-wrap(v-else-if="type === 'number' && currency")
      span.value {{ value | currency }}
    div(v-else)
      b(v-if="!value && placeholderKey") {{ placeholderKey }}
      span.value {{ value || "-" }}
    v-tooltip(bottom, color="primary", v-if="prev !== undefined")
      span.mr-1 Prev:
      span(v-if="type === 'date'") {{ prev | date("MM/DD/Y") }}
      span(v-else-if="type === 'number' && currency") {{ prev | currency }}
      span.text-uppercase(v-else) {{ prev }}
      template(#activator="{ on, attrs }")
        v-icon.history-icon(
          v-bind="attrs",
          v-on="on",
          @click.stop,
          color="primary"
        ) mdi-history
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import moment from "@/helpers/moment";

@Component
export default class SimpleNumber extends Vue {
  @Prop({ type: Boolean, default: true })
  currency!: boolean;

  @Prop({ type: Boolean })
  disabled!: string;

  @Prop({ default: "number" })
  type!: string;

  @Prop()
  value!: any;

  @Prop()
  prev?: any;

  @Prop({ type: Array })
  items?: Array<any>;

  @Prop({ type: Number })
  max?: number;

  @Prop({ type: Number })
  min?: number;

  @Prop()
  placeholderKey?: string;

  editing = false;

  format(v: string | number) {
    if (this.type === "date") {
      return moment(v).toDate();
    }
    if (this.type === "number") {
      let n = +v;
      if (this.max !== undefined && n > this.max) {
        n = this.max;
      }
      if (this.min !== undefined && n < this.min) {
        n = this.min;
      }
      return n;
    }
    return v;
  }

  async enterEditing($event: PointerEvent) {
    if (this.disabled) return;
    this.editing = true;
    await this.$nextTick();
    if (this.$refs.input) {
      (
        (this.$refs.input as Vue).$el.querySelector("input") as HTMLInputElement
      ).select();
      (this.$refs.input as Vue & { isMenuActive: boolean }).isMenuActive = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-number {
  position: relative;
}
.v-text-field ::v-deep > .v-input__control > .v-input__slot {
  &:before {
    border: none;
  }
}
.editable span.value {
  display: inline-block;
  min-width: 4em;
  border-bottom: 1px dashed var(--v-primary-base) !important;
  cursor: pointer;
}
.history-icon {
  font-size: 12px !important;
  vertical-align: text-top;
  position: absolute;
  right: 3px;
}
</style>
