<template lang="pug">
.d-flex.align-center.mx-2
  .mr-2.text-body-2(style="", v-if="total")
    span {{ start }} - {{ end }} / {{ total }} Records
  .d-flex.align-center(v-if="perPage && total")
    span.ml-2
    v-select(v-model="perPage", :items="pageSizeOptions", hide-details, dense)
    span Records / Page
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class PaginationIndicator extends Vue {
  @Prop({ type: Number })
  value!: number;

  @Prop({ type: Number })
  currentPage!: number;

  @Prop({ type: Number })
  total!: number;

  @Prop()
  pageSizeOptions!: number[];

  get perPage() {
    return this.value;
  }

  set perPage(v) {
    this.$emit("input", v);
  }

  get start() {
    return (this.currentPage - 1) * this.perPage + 1;
  }

  get end() {
    if (!this.perPage) return this.total;
    return Math.min(this.total, this.currentPage * this.perPage);
  }
}
</script>

<style lang="scss" scoped>
:deep(.v-select__selections input) {
  display: none;
}
</style>
