<template>
  <v-footer id="pages-core-footer" absolute color="transparent" dark>
    <v-container>
      <v-row
        align="center"
        justify="center"
        justify-md="space-between"
        no-gutters
        class="flex-column flex-md-row"
      >
        <div>
          <external-config-reader
            text="Privacy Policy"
            config-key="privacyPolicy"
          />
          <external-config-reader text="Terms of Use" config-key="termOfUse" />
        </div>
        <div class="footer-text font-weight-light pt-0 text-right">
          v{{ $version }} &copy; 2020-{{ new Date().getFullYear() }} Kalamata
          Capital Group
        </div>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import ExternalConfigReader from "@/components/base/ExternalConfigReader.vue";

export default {
  name: "PagesCoreFooter",
  components: { ExternalConfigReader },
  data: () => ({
    links: [],
  }),
};
</script>

<style lang="sass" scoped>
.footer-text
  color: #FFFFFF
  opacity: 0.8
  font-size: 12px
  line-height: 24px
  font-weight: 500
  text-decoration: none
  text-transform: uppercase
</style>
