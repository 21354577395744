import { File } from "@/resources/interfaces";
import JsFileDownloader from "js-file-downloader";

export function downloadUrl(file?: Partial<File>, preview = false) {
  if (!file?.id) return "";
  let params = "download=1";
  if (preview) {
    params += "&preview=1";
  }
  const url = `${process.env.VUE_APP_API_BASE}/file/${
    file.id
  }?${params}&token=${localStorage.getItem("token")}`;
  return url;
}

export async function fileDownload(url: string, name?: string) {
  try {
    // if (download) {
    //   try {
    //     download.abort(new Error('MANUALLY_ABORT'));
    //   } catch (e) {
    //     console.error(e);
    //   }
    // }

    if (!url) {
      throw new Error("Download url is required.");
    }
    // message.destroy();
    // message.success('Start downloading...');
    const jsFileDownloader = new JsFileDownloader({
      url,
      timeout: 30 * 60 * 1000,
      autoStart: false,
      filename: name,
      nativeFallbackOnError: false,
      process: (event) => {
        if (!event.lengthComputable) return; // guard
        const downloadingPercentage = Math.floor(
          (event.loaded / event.total) * 100
        );
        console.log("downloadingPercentage:", downloadingPercentage);
        // setDownloadProgress(
        //   downloadingPercentage < 10
        //     ? Math.min(10, downloadingPercentage * 10)
        //     : downloadingPercentage
        // );
        // if (downloadingPercentage === 100) {
        //   message.destroy();
        //   message.success('Download completed');
        //   setDownloadProgress(-1);
        //   setDownload(null);
        // }
        return undefined; // Explicitly return undefined
      },
    });
    // setDownload(jsFileDownloader);
    await jsFileDownloader.start().catch(function (reason: any) {
      // setDownloadProgress(-1);
      // setDownload(null);
      console.error(reason);
      // if (String(reason) !== 'MANUALLY_ABORT') {
      //   message.destroy();
      //   message.error('Failed to download');
      // }
    });
  } catch (e) {
    console.error(e);
    // message.destroy();
    // message.error('Failed to download');
  }
}
