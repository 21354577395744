<template>
  <div id="notifications">
    <base-material-snackbar
      v-for="(notification, index) in notifications"
      :key="notification.id"
      :value="true"
      :type="notification.type"
      @dismiss="dismissNotification(index)"
      :timeout="notification.timeout"
      :style="{ 'margin-top': index * 90 + 50 + 'px' }"
      top
      right
    >
      {{ notification.message }}
      <a
        v-if="notification.link"
        :href="notification.link.path"
        target="_blank"
        >{{ notification.link.title }}</a
      >
    </base-material-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapState, mapMutations } from "vuex";

@Component({
  computed: {
    ...mapState(["notifications"]),
  },
  methods: {
    ...mapMutations({
      dismissNotification: "DISMISS_NOTIFICATION",
    }),
  },
})
export default class Notifications extends Vue {
  type = "success";
  show = false;
  notifications!: { message: string; type: string; timeout: number }[];
  update(index: number, value: boolean) {
    // console.log(index, value);
    if (!value) {
      // @ts-ignore
      this.dismissNotification(index);
    }
  }
}
</script>
